import { css, Theme } from '@emotion/react';

export default {
  promo: ({ isLite, spacings, mq }: Theme) =>
    css({
      ...(isLite && {
        [mq.GROUP_2_MAX_WIDTH]: {
          marginTop: `${spacings.SEXTUPLE}rem`,
        },
      }),
    }),
};
